import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';


@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'frontend';
  constructor(private analytics: AngularFireAnalytics) {
    this.analytics.logEvent('learner_app_open', { "component": "AppComponent" });
  }
}
