import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { QuestionsService } from './questions.service';

export { AuthService } from './auth/auth.service';
export { QuestionsService } from './questions.service';
export { Services };

const Services = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  QuestionsService,
  AuthService,
];
