<div class="forgot-page">
    <div class="sign-in">
        <div class="sign-in-inner">
            <div class="logo">
                <a href="#"><img src="assets/images/logo.svg"></a>
            </div>
            <div class="signin-box">
                <h4>Forgot Password</h4>
                <p>Enter your registered email</p>
                <app-http-message [type]="errorType" [data]="forgotPasswordResponse" class="error-msg">
                </app-http-message>

                <form (keydown.enter)="$event.preventDefault()" [formGroup]="forgotPasswordForm" #fform="ngForm"
                    (ngSubmit)="onSubmit()">
                    <div class="input">
                        <label>Email</label>
                        <input type="text" formControlName="email" id="email">
                        <div class="form-text error text-danger" *ngIf="formErrors.email">
                            {{formErrors.email}}
                        </div>
                    </div>
                    <div class="forget-pass-cont">
                        <p>You will receive a password reset link by email</p>
                    </div>
                    <div class="forget-btns">
                        <button class="cursor-pointer yes-btn" [disabled]="forgotPasswordForm.invalid" type="submit"
                            [ngClass]="forgotPasswordForm.invalid ? 'btn-disabled' : ''">Yes</button>
                        <button class="no-btn" type="button" [routerLink]="['/']">No</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="support-btn">
        <a href="#"><i><img src="assets/images/user-ic.svg"></i><span>Support</span></a>
    </div>
</div>