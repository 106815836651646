import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { Services } from '@shared/services';
import { HttpCalIInterceptor } from '@shared/services/auth/http.interceptor';
import { ToastyModule } from 'ngx-toasty';
import { AngularFireAnalyticsModule, CONFIG, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
// import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
// import { AngularFireModule } from "@angular/fire/compat";
import { environment } from '../environments/environment';
import { AppComponent } from './../app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthComponent } from './auth/auth.component';
import { AuthModule } from './auth/auth.module';
import { LoginModule } from './auth/login/login.module';
import { MainComponent } from './main/main.component';
import { SharedModule } from './shared/shared.module';
import { ConnectionServiceModule } from 'ng-connection-service';
import { AngularFireModule } from '@angular/fire';

export function tokenGetter(): any {
  return localStorage.getItem('admin-token');
}

// export function init_app(appSettingsService: AppInitService) {
//     return () => appSettingsService.init_app();
// }

@NgModule({
  declarations: [AppComponent, MainComponent, AuthComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    AuthModule,
    ConnectionServiceModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        // whitelistedDomains: new Array(new RegExp('^null$'))
      },
    }),

    ToastyModule.forRoot(),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideAnalytics(() => getAnalytics())
  ],
  bootstrap: [AppComponent],
  providers: [
    ...Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCalIInterceptor,
      multi: true,
    },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: CONFIG, useValue: {
        // send_page_view: false,
        allow_ad_personalization_signals: false,
        anonymize_ip: true
      }
    },
    {
      provide: DEBUG_MODE, useValue: true
    },
  ],
})
export class AppModule { }
