import { HttpClient } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { EventEmitter, Injectable } from '@angular/core';
import { Question } from '@shared/interfaces/questions';
import { apiUrl, decrypt } from '@shared/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export type UserAnswerDTO = {
  question_id: string;
  answers: any[string | number];
  batch_user_id: string;
  type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  constructor(
    private readonly httpService: HttpClient,
    private analytics: AngularFireAnalytics,
  ) { }

  public currentSubSectionChoice: number;

  public allAnswers: UserAnswerDTO[] = [];

  public batch_name = '';

  public currentSectionChoice: number;

  public allQuestions: any;

  public sections: {
    subsection: any;
    id: number,
    name: string,
    status: string,
  }[];

  public currentBatchId: any;

  public totalExamTime: any;

  public canUserLogout = false;
  sectionUpdated: EventEmitter<any> = new EventEmitter();

  timeOut: EventEmitter<any> = new EventEmitter();

  enableLoading: EventEmitter<any> = new EventEmitter();

  disableLoading: EventEmitter<any> = new EventEmitter();

  timerAvailable: EventEmitter<any> = new EventEmitter();

  setCanUserLogout(data: boolean): any {
    this.canUserLogout = data;
  }

  enablingLoadingOnQuestionPage(): any {
    this.enableLoading.emit();
  }

  async getAllQuestions(): Promise<any> {
    try {
      let resp: any;
      resp = await this.httpService
        .get(apiUrl(`question/all-questions/${this.currentBatchId}`))
        .toPromise();
      if (resp) {
        this.allQuestions = decrypt(resp.data);
        for (const section of this.allQuestions) {
          for (const subsection of section.subsection) {
            for (const question of subsection.questions) {
              for (const questionDetail of question) {
                for (const queImage of questionDetail.questionImages) {
                  const { url } = queImage;
                  // console.log(queImage.url);
                  this.cacheImages(url).subscribe(
                    data => {
                      // console.log(data);
                    },
                    err => {
                      // console.log(err);
                    }
                  );
                }
              }
            }
          }
        }
      }
      return this.allQuestions;
    } catch (error) {
      return throwError(JSON.parse(JSON.stringify(error)));
    }
  }

  cacheImages(link: string): any {
    return this.httpService.get(apiUrl(link)).pipe(
      map((data: any) => {
        // console.log(data);
        return link;
      }),
      catchError(error => {
        // console.log(error);
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }
  disableLoadingOnQuestionPage(): any {
    this.disableLoading.emit();
  }

  ExamAbortedTimeOut(): any {
    this.timeOut.emit();
  }

  setCurrentBatchId(id: number): any {
    localStorage.setItem('batch_user', id.toString());
    this.currentBatchId = id;
  }
  setCurrentBatch(name: string): any {
    this.batch_name = name;
  }

  setBatchIdOnLoading(): any {
    const batchId = localStorage.getItem('batch_user');
    if (batchId) {
      localStorage.setItem('batch_user', batchId);
      this.currentBatchId = parseInt(batchId, 10);
    }
  }

  setCurrentSectionChoice(choice: number): any {
    this.currentSectionChoice = choice;
    this.sectionUpdated.emit();
  }

  setCurrentSubSectionChoice(choice: number): any {
    this.currentSubSectionChoice = choice;
    this.sectionUpdated.emit();
  }

  getCurrentSectionChoice(): string {
    if (this.currentSectionChoice) {
      if (this.currentSectionChoice === 9999) {
        return 'Final Results';
      }
      const section = _.find(this.sections, { id: this.currentSectionChoice });
      return section.name;
    }

    return '';
  }

  getCurrentSubSectionChoice(): string {
    if (this.currentSubSectionChoice) {
      if (this.currentSubSectionChoice === 9999) {
        return '';
      }
      const section = _.find(this.sections, { id: this.currentSectionChoice });
      if (section) {
        const subsections = section.subsection;
        const subsection = _.find(subsections, {
          id: this.currentSubSectionChoice,
        });
        if (subsection) {
          return subsection.name;
        }
      }
    }
    return '';
  }
  getCurrentSubSectionTime(): number {
    return this.totalExamTime;
  }

  getSectionAndSubSection(courseId: number): any {
    return this.httpService
      .get(apiUrl(`course/exam/${courseId}/${this.currentBatchId}`))
      .pipe(
        map((resp: {
          data: {
            sections: any[],
            submitted_section: number,
            submitted_subsection: number,
            total_submitted_questions: number,
            total_time: number,
            main: UserAnswerDTO[],
          }
        }) => {
          const batch_user = localStorage.getItem('batch_user');
          this.totalExamTime = parseInt(resp.data.total_time.toString(), 10);
          if (localStorage.getItem(`time${batch_user}`)) {
            const localExamTime = parseInt(localStorage.getItem(`time${batch_user}`), 10);
            if (this.totalExamTime > localExamTime) {
              this.totalExamTime = localExamTime;
            }
          }
          this.setLocalTiming(this.totalExamTime);
          this.timerAvailable.emit();

          this.sections = resp.data.sections;
          this.allAnswers = resp.data.main;

          localStorage.setItem(
            `answers${batch_user}`,
            JSON.stringify({ main: this.allAnswers })
          );
          let submittedSection = 0;
          let submittedSubSection = 0;
          if (this.sections.length > resp.data.submitted_section) {
            for (const section of this.allQuestions) {
              const totalSubSections = section.subsection.length;
              for (const subsection of section.subsection) {
                for (const question of subsection.questions) {
                  const subQueLength = question.length;
                  let submittedQueLength = 0;
                  for (const questionDetail of question) {
                    const index = _.findIndex(this.allAnswers, {
                      question_id: questionDetail.id,
                    });
                    if (index !== -1) {
                      submittedQueLength++;
                    }

                    if (submittedQueLength === subQueLength) {
                      submittedSubSection++;
                    }
                    if (submittedSubSection === totalSubSections) {
                      submittedSection++;
                      submittedSubSection = 0;
                    }
                  }
                }
              }
            }

            this.setSubmittedSections(submittedSection);
            this.setSubmittedSubSections(submittedSubSection);
            if (this.sections.length !== submittedSection) {
              this.setCurrentSectionChoice(
                this.sections[submittedSection].id
              );

              this.setCurrentSubSectionChoice(
                this.sections[submittedSection].subsection[
                  submittedSubSection
                ].id
              );
            }
          }

          return resp.data;
        }),
        catchError(error => {
          console.log(error);
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }

  getSubSectionsQuestions(id: number): Question[] {
    for (const section of this.allQuestions) {
      for (const subsection of section.subsection) {
        if (subsection.id === id) {
          return subsection.questions[0];
        }
      }
    }
  }

  // FIXED: Disable abortExam logic
  // abortExam(): any {
  //   return this.submitAllAnswers().subscribe(
  //     () => {
  //       return this.httpService
  //         .get(apiUrl(`answers/abort-exam/${this.currentBatchId}`))
  //         .pipe(
  //           map((resp: any) => {
  //             return resp.data;
  //           }),
  //           catchError(error => {
  //             return throwError(JSON.parse(JSON.stringify(error)));
  //           })
  //         );
  //     },
  //     (err: any) => {
  //       console.log(err);
  //     }
  //   );
  // }
  getReport(): any {
    return this.httpService
      .get(apiUrl(`reports/user/score/${this.currentBatchId}`))
      .pipe(
        map((resp: any) => {
          return resp.data;
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }

  getExamDetails(): any {
    return this.httpService
      .get(apiUrl(`course/exam-details/${this.currentBatchId}`))
      .pipe(
        map((resp: any) => {
          return resp.data;
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }

  submitAnswer(payload: UserAnswerDTO, questionId: any): any {
    this.allAnswers.push(payload);
    const batch_user = localStorage.getItem('batch_user');
    this.totalExamTime = localStorage.getItem(`time${batch_user}`)
    return this.httpService.post(apiUrl(`answers`), { ...payload, total_exam_time: this.totalExamTime }).pipe(
      map((resp: any) => {
        // return resp.data;
        if (!localStorage.getItem(`answers${batch_user}`)) {
          localStorage.setItem(
            `answers${batch_user}`,
            JSON.stringify({ main: [payload] })
          );
        } else {
          const localAnswers = JSON.parse(localStorage.getItem(`answers${batch_user}`));
          localAnswers.main.push(payload);
          localStorage.setItem(`answers${batch_user}`, JSON.stringify(localAnswers));
        }
        for (const section of this.allQuestions) {
          for (const subsection of section.subsection) {
            for (const question of subsection.questions) {
              for (const questionDetail of question) {
                if (questionDetail.type === 'sub-question') {
                  if (questionDetail.id === questionId) {
                    return questionDetail;
                  }
                } else {
                  if (questionDetail.id === questionId) {
                    return questionDetail;
                  }
                }
              }
            }
          }
        }
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      }),
    );
  }
  submitAllAnswers(): any {
    return this.httpService.post(apiUrl(`answers/all`), this.allAnswers).pipe(
      map((resp: any) => {
        this.analytics.logEvent('finish_test', { "component": "QuestionsComponent" });
        this.allAnswers = [];
        return resp.data;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      }),
    );
  }
  getCompletedExamDetails(id): any {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const user = userData.user;
    return this.httpService.get(apiUrl(`course/user-data/` + this.currentBatchId)).pipe(
      map((resp: any) => {
        return resp.data;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }
  getExamStatus(): any {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const user = userData.user;
    return this.httpService.get(apiUrl(`course/exam/status/${user.id}`)).pipe(
      map((resp: any) => {
        return resp.data;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  downloadReport(): any {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const user = userData.user;
    return this.httpService
      .get(apiUrl(`reports/download/user/${this.currentBatchId}`))
      .pipe(
        map((resp: any) => {
          return resp.data;
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }
  downloadPdf(link: string, name?: any): any {
    return this.httpService.get(apiUrl(link), { responseType: 'blob' }).pipe(
      map(
        (data: any) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          // if you want to open PDF in new tab
          // window.open(fileURL);
          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          if (name) {
            a.download = name;
          } else {
            const date = moment().format('DD-MM-YYYY');
            a.download = `NorthStandard ECDIS Training Assessment - ${this.batch_name} - ${date}`;
          }
          document.body.appendChild(a);
          a.click();
        },
        catchError(error => {
          console.log(error);
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      )
    );
  }

  setSubmittedSections(sectionId: number): any {
    // tslint:disable-next-line: variable-name
    const batch_user = localStorage.getItem('batch_user');
    localStorage.setItem(`section${batch_user}`, sectionId.toString());
  }
  setSubmittedSubSections(subsectionId: number): any {
    // tslint:disable-next-line: variable-name
    const batch_user = localStorage.getItem('batch_user');
    localStorage.setItem(`subsection${batch_user}`, subsectionId.toString());
  }
  // tslint:disable-next-line: typedef
  setLocalTiming(secs: number) {
    // tslint:disable-next-line: variable-name
    const batch_user = localStorage.getItem('batch_user');
    if (batch_user) {
      localStorage.setItem(`time${batch_user}`, secs.toString());
    }
  }
}
