import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private navigationRoute: Router
  ) {}

  errorType = 'custom-danger';
  PasswordFieldTextType = false;
  ConfirmPasswordFieldTextType = false;

  token: string;
  eye1 = true;

  eye2 = true;

  resetForm: FormGroup;
  resetResponse: any;

  @ViewChild('rform') resetFormDirective: any;

  formErrors: any = {
    password: '',
    confirmPassword: '',
  };

  validationMessages: any = {
    password: {
      required: 'password is required.',
      minlength: 'password must be at least 8 characters long.',
      maxlength: 'password cannot be more than 25 characters long.',
    },
    confirmPassword: {
      required: 'password is required.',
      minlength: 'password must be at least 8 characters long.',
      NoPasswordMatch: 'Password & Confirm Password does not match.',
      maxlength: 'password cannot be more than 25 characters long.',
    },
  };
  static passwordMatchValidator(control: AbstractControl): any {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPasswordMatch: true });
    }
  }

  togglePassword(): any {
    this.PasswordFieldTextType = !this.PasswordFieldTextType;
    this.eye1 = !this.eye1;
  }
  toggleConfirmPassword(): any {
    this.ConfirmPasswordFieldTextType = !this.ConfirmPasswordFieldTextType;
    this.eye2 = !this.eye2;
  }
  inputPasswordErrorClass(): boolean {
    const isError = this.formErrors.password.length;
    return isError;
  }

  inputConfirmPasswordErrorClass(): boolean {
    const isError = this.formErrors.confirmPassword.length;
    return isError;
  }

  createForm(): any {
    this.resetForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(25),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(25),
          ],
        ],
      },
      {
        validators: ResetPasswordComponent.passwordMatchValidator,
      }
    );
    this.resetForm.valueChanges.subscribe(data => {
      this.onValueChanged(data);
    });
  }

  onValueChanged(data?: any): any {
    if (!this.resetForm) {
      return;
    }
    const form = this.resetForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => (this.token = params.token));
    this.createForm();
  }

  onSubmit(): any {
    const resetFormData = {
      password: this.resetForm.value.password,
    };
    // remove old token
    localStorage.removeItem('token');

    // setting token before request
    localStorage.setItem('token', this.token);
    this.authService.setPassword(resetFormData).subscribe(
      (response: any) => {
        this.resetFormDirective.resetForm();
        this.resetForm.reset({
          password: '',
          confirmPassword: '',
        });
        this.errorType = 'successful';
        this.resetResponse = response.message;
        this.navigationRoute.navigate(['/']);
      },
      (error: any) => {
        this.resetResponse = 'Invalid Link';
      }
    );
  }
}
