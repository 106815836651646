import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Pipe({ name: 'updateanchor' })
export class UpdateAnchor implements PipeTransform {
    transform(value: any) {
        if (value !== undefined && value !== null && value !== '') {
            return value.replace(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g, function (anchor) {
                if (anchor !== undefined && anchor !== null) {
                    return anchor.replace(/href="(.*?)"/, function (m, $1) {
                        if ($1.indexOf('http') < 0) {
                            // return "[routerLink]=\"['/our-work']\"";
                            return 'href="' + environment.baseUrl + $1 + '" ';
                        } else {
                            return 'href="' + $1 + '"';
                        }
                    });
                }
            });
        }
    }
}

@Pipe({
    name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

    transform(value: string, limit: any = 25, trail: any = "..."): string {
        if (value)
            return value.length > limit ? value.substring(0, limit) + trail : value;
        else
            return null;
    }
}


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}


@Pipe({ name: 'ucFirst' })
export class UcFirstPipe implements PipeTransform {
    transform(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
}
