import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Components } from './components';
import { Pipes } from './pipes';
import { AuthGuard } from './services/auth/auth.guard';

const Modules = [FormsModule, ReactiveFormsModule];

@NgModule({
  imports: [CommonModule, ...Modules],
  declarations: [...Components, ...Pipes],
  exports: [...Components, ...Pipes],

  entryComponents: [],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AuthGuard],
    };
  }
}
