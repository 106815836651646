export const environment = {
  production: true,
  deployUrl: '',
  baseUrl: 'ecdis-learner/',
  apiUrl: 'https://ecdis-staging-api.mcd-uk.com/api',
  apiEndPoint: 'https://ecdis-staging-api.mcd-uk.com',
  hmr: false,
  firebaseConfig: {
    apiKey: "AIzaSyBhe0fmAL4bKjpJY_x0JXmzE4bcDRIcEPQ",
    authDomain: "ecdis-3da51.firebaseapp.com",
    projectId: "ecdis-3da51",
    storageBucket: "ecdis-3da51.appspot.com",
    messagingSenderId: "93755849616",
    appId: "1:93755849616:web:c10da46c35fc18626a1115",
    measurementId: "G-YLJXFZMWGT"
  }
};
