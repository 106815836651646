import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@shared/services';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  errorType = 'custom-danger';
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  forgotPasswordForm: FormGroup;
  forgotPasswordResponse: any;

  fieldTextType = false;
  @ViewChild('fform') forgotPasswordFormDirective: any;

  formErrors: any = {
    email: '',
  };

  validationMessages: any = {
    email: {
      required: 'Email is required.',
      email: 'Email not in valid format.',
    },
  };

  createForm(): any {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.forgotPasswordForm.valueChanges.subscribe(data => {
      this.onValueChanged(data);
    });
  }

  onValueChanged(data?: any): any {
    if (!this.forgotPasswordForm) {
      return;
    }
    const form = this.forgotPasswordForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit(): any {
    const email = this.forgotPasswordForm.value;
    this.authService.forgotPassword(email).subscribe(
      (response: any) => {
        this.forgotPasswordFormDirective.resetForm();
        this.forgotPasswordForm.reset({
          email: '',
        });
        this.errorType = 'successful';
        this.forgotPasswordResponse = response;
      },
      (error: any) => {
        this.forgotPasswordResponse = error.error.message[0];
      }
    );
  }

  ngOnInit(): void {
    this.createForm();
  }
}
