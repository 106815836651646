import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  roleName: any;
  user: any;
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //console.log(route);
    let return_data: any = await new Promise((resolve, reject) => {
      const token = this.authService.getToken();
      this.user = this.authService.getUser();
      const loggedinuser = JSON.parse(this.user);
      this.roleName = loggedinuser.user.role.name;
      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });

    if (
      this.roleName === 'learner' &&
      (state.url === '/home')
      //  || state.url === '/profile')
    ) {
      return return_data;
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }
}
