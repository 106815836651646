import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { AppStorage } from '@shared/cookies/app-storage';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  reset_token: string;

  dataStore: {
    token: string;
    user: any;
  };
  private _token: BehaviorSubject<string>;
  private _user: BehaviorSubject<any>;
  constructor(
    //  @Inject(AppStorage) private appStorage: Storage,
    private readonly httpService: HttpClient
  ) {
    this.dataStore = {
      token: null,
      user: null,
    };

    this._token = new BehaviorSubject(this.dataStore.token);
    this._user = new BehaviorSubject(this.dataStore.user);

    this.init();
  }
  public get $token(): Observable<any> {
    return this._token.asObservable();
  }

  public get token(): any {
    return this.dataStore.token;
  }

  public set token(value: any) {
    this.dataStore.token = value;
    // this.appStorage.setItem('token', this.dataStore.token);
    this._token.next(this.dataStore.token);
  }

  public get user(): any {
    return this.dataStore.user;
  }
  public set user(value: any) {
    this.dataStore.user = value;
    // this.appStorage.setItem('user', JSON.stringify(this.dataStore.user));
    this._user.next(this.dataStore.user);
  }

  public get $user(): Observable<any> {
    return this._user.asObservable();
  }

  init() {
    //  this.token = this.appStorage.getItem('token');
    try {
      this.user = JSON.parse(localStorage.getItem('user'));
    } catch (error) {
      this.user = null;
    }

    if (this.token) {
      this.isLoggedIn = true;
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser(): any {
    return localStorage.getItem('userData');
  }

  loggedIn() {
    const token: string = localStorage.getItem('token');
    return token != null;
  }

  setPassword(request: any): any {
    return this.httpService.post('auth/reset-password', request).pipe(
      map((resp: any) => {
        localStorage.removeItem('token');
        // commented for SSO
        /* this.token = resp.data.token;
        this.user = resp.data.user;
        this.isLoggedIn = true;
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('userData', JSON.stringify(resp.data)); */
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  login(request: any): any {
    return this.httpService.post('auth/login', request).pipe(
      map((resp: any) => {
        this.token = resp.data.token;
        if (resp.data.user.role.name !== 'learner') {
          let err = new Error('Invalid Credentials');
          throw err;
        }
        this.user = resp.data.user;
        this.isLoggedIn = true;
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('userData', JSON.stringify(resp.data));
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  logout(request?: any): any {
    this.user = null;
    if (request) {
      return this.httpService.get('auth/reset-log');
    }
    // this.appStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userData');
    localStorage.removeItem('batch_user');
    return this.httpService.get('auth/logout');
  }

  logoutAngular() {
    // this.appStorage.clear();
    this.isLoggedIn = false;
    this.token = null;
    this.user = null;

    return true;
  }

  forgotPassword(request: any): any {
    return this.httpService
      .post<any>(`auth/learner/forgot-password`, request)
      .pipe(
        map(response => {
          if (response.status === 200) {
            const data = response.message;
            return data;
          } else {
            this.token = null;
            this.user = null;
            return response;
          }
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }

  refreshToken(): any {
    const token: string = this.token;
    return this.httpService
      .post<any>(`auth/refresh-token`, { token })
      .pipe(
        map(response => {
          if (response.status === 200) {
            const data = response.data;
            this.token = data.token;
            this.user = data.user;
            this.isLoggedIn = true;
            return response;
          } else {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            return response;
          }
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }
}
