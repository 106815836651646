import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-http-message',
  templateUrl: './http-message.component.html',
  styleUrls: ['./http-message.component.css'],
})
export class HttpMessageComponent implements OnInit {
  errorMessage: any = [];
  showMessage = false;
  errorType: any = 'custom-danger';

  @Input('type') set type(value) {
    if (value === 'error') {
      this.errorType = 'custom-danger';
    } else {
      this.errorType = value;
    }
  }

  @Input('data') set data(response: any) {
    this.errorMessage = [];
    if (response) {
      if (typeof response === 'string') {
        this.showMessage = true;
        this.errorMessage.push(response);
      } else {
        this.showMessage = true;
        if (response.message !== undefined) {
          this.errorMessage.push(response.message);
        } else if (response.messages !== undefined) {
          const allErrors: any = response.messages;
          Object.keys(allErrors).map(key => {
            for (const er of allErrors[key]) {
              this.errorMessage.push(er);
            }
          });
        } else if (response.message !== undefined || response.message != null) {
          this.errorMessage.push(response.message);
        }
      }
    } else {
      this.showMessage = false;
      this.errorMessage = [];
    }
  }

  constructor() {}
  ngOnInit(): void {}

  closeMessage(): any {
    this.showMessage = false;
    setTimeout(() => {
      this.errorMessage = [];
    }, 500);
  }
}
