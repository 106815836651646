import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, QuestionsService } from '@shared/services';
import { apiUlrValue, apiUrl } from '@shared/utils';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpCalIInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private questionsService: QuestionsService
  ) {}
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    const token = localStorage.token;
    if (!token) {
      // tslint:disable-next-line: no-shadowed-variable
      const req1 = req.clone({
        url: apiUrl(req.url),
      });
      // allow http interceptor to handle error for auth route
      if (
        req.url === 'auth/login' ||
        req.url === 'auth/forgot-password' ||
        req.url === 'auth/reset-password'
      ) {
        return next.handle(req1).pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              switch ((err as HttpErrorResponse).status) {
                case 422:
                  return throwError(err.error);
                case 403:
                  return throwError(err);
                case 401:
                  if (
                    err &&
                    err.error &&
                    err.error.message === 'Unauthorized'
                  ) {
                    return this.handle401Error(req1, next);
                  } else {
                    return throwError(err);
                  }
                case 400:
                  return throwError(err.error);
              }
            } else {
              return throwError(err);
            }
          })
        );
      } else {
        return next.handle(req1);
      }
    }
    let req1 = null;
    if (req.url.indexOf(apiUlrValue()) >= 0) {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
        url: req.url,
      });
      return next.handle(req1);
    } else {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
        url: apiUrl(req.url),
      });
    }

    // return next.handle(req1);
    return next.handle(req1).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch ((err as HttpErrorResponse).status) {
            case 422:
              return throwError(err.error);
            case 403:
              return throwError(err);
            case 401:
              if (err && err.error && err.error.message === 'Unauthorized') {
                return this.handle401Error(req1, next);
              } else {
                return throwError(err);
              }
            case 400:
              return throwError(err.error);
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    // disable auto navigation to login route on reset password route
    if (request.url !== apiUrl('auth/reset-password')) {
      this.authService.logout();
      this.questionsService.setCanUserLogout(true);
      return this.router.navigate(['/']);
    }
  }
}
