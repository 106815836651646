<div class="forgot-page">
    <div class="sign-in">
        <div class="sign-in-inner">
            <div class="logo">
                <a href="#"><img src="assets/images/logo.svg"></a>
            </div>
            <div class="signin-box">
                <h4>Set Password</h4>
                <p>Create your new password</p>
                <app-http-message [type]="errorType" [data]="setResponse" class="alert-successful"></app-http-message>

                <form [formGroup]="setForm" #rform="ngForm" (ngSubmit)="onSubmit()">
                    <div [class]="inputPasswordErrorClass()?'input mb-0': 'input'">
                        <label>New Password</label>
                        <input [type]="PasswordFieldTextType?'text':'password'" formControlName="password">
                        <span (click)="togglePassword()"><img
                                [src]="eye1?'assets/images/eye-icon.svg':'assets/images/eye-slash.svg'"></span>
                    </div>
                    <div class="form-text error text-danger mb-4" *ngIf="formErrors.password">
                        {{formErrors.password}}
                    </div>
                    <div [class]="inputConfirmPasswordErrorClass()?'input mb-0': 'input'">
                        <label>Confirm New Password</label>
                        <input [type]="PasswordFieldTextType?'text':'password'" formControlName="confirmPassword">
                        <!--   <input [type]="ConfirmPasswordFieldTextType?'text':'password'"
                            formControlName="confirmPassword"> -->
                        <!-- <span (click)="toggleConfirmPassword()"><img
                                [src]="eye2?'assets/images/eye-icon.svg':'assets/images/eye-slash.svg'"></span> -->
                    </div>
                    <div class="form-text error text-danger mb-4" *ngIf="formErrors.confirmPassword">
                        {{formErrors.confirmPassword}}
                    </div>
                    <div class="forget-btns">
                        <button class="yes-btn" [disabled]="setForm.invalid" type="submit">Confirm</button>
                        <button class="no-btn" type="button" [routerLink]="['/forgot-password']">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="support-btn">
        <a><i><img src="assets/images/user-ic.svg"></i><span>Support</span></a>
    </div>
</div>