<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop:true }"></ngx-loading>

<div class="login-page">
    <div class="sign-in">
        <div class="sign-in-inner">
            <div class="logo">
                <a href="#"><img src="assets/images/Combined_shape.png"></a>
            </div>
            <div class="signin-box">
                <h4>Learner</h4>
                <p>Please use your ECDIS credentials to log in</p>
                <app-http-message [data]="loginResponse" class="success-msg"></app-http-message>

                <form [formGroup]="loginForm" #lform="ngForm" (ngSubmit)="onSubmit()">
                    <div class="input">
                        <label for="email">Email*</label>
                        <input type="text" name="email" formControlName="email" id="email" />
                        <div class="form-text error text-danger" *ngIf="formErrors.email">
                            {{formErrors.email}}
                        </div>
                    </div>
                    <div [class]="inputErrorClass()?'input mb-0': 'input'">
                        <label for="password">Password*</label>
                        <input [type]="fieldTextType?'text':'password'" name="password" formControlName="password"
                            id="password" />
                        <span (click)="togglePassword()"><img
                                [src]="eye?'assets/images/eye-icon.svg':'assets/images/eye-slash.svg'"></span>
                    </div>
                    <div class="form-text error text-danger mb-4" *ngIf="formErrors.password">
                        {{formErrors.password}}
                    </div>
                    <div class=" input">
                        <input [disabled]="loginForm.invalid" type="submit" value="Login" class="input-btn"
                            [ngClass]="loginForm.invalid ? 'btn-disabled' : ''">
                    </div>
                    <div class="forget-password">
                        <a [routerLink]="['/forgot-password']">Forgot Password?</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="support-btn">
        <a routerLink="/support"><i><img src="assets/images/user-ic.svg"></i><span>Support</span></a>
    </div>
</div>

<ng-template #splashDialog let-data>
    <div class="dialog-content-wrapper">
        <h4 align="center" class="mb-3">
            Terms & Conditions
        </h4>
        <div mat-dialog-content class="overflow-y-auto">
            <div *ngIf="data.content" [innerHTML]="data.content" class="initHTML"></div>
        </div>
        <div class="mt-2 d-flex gap-2 mx-4">
            <input id="termsCheckbox" class="" type="checkbox" [checked]="isCheckboxChecked"
                (change)="setChecked($event)" />
            <label for="termsCheckbox">
                By clicking agree and continue, I accept the terms and conditions to use this application.
            </label>
        </div>
        <div class="submit-answer mx-auto mt-4 text-center">
            <button type="button" [disabled]="!isCheckboxChecked" (click)="dialog.closeAll()">Accept</button>
        </div>
    </div>
</ng-template>